import {
  Tab as ReactTab,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
  Tabs as ReactTabs,
  type TabProps as ReactTabProps,
  type TabsProps as ReactTabsProps,
} from "react-tabs";
import styled from "styled-components";
import media from "../../theme/media";
import { tabListStyles, tabStyles } from "./styled";

const SELECTED_TAB_PANEL = "react-tabs__tab-panel--selected";

type TabListVariant = "default" | "transparent";

interface TabListProps {
  variant?: TabListVariant;
}

export const TabList = styled(ReactTabList)<TabListProps>`
  ${tabListStyles}
`;

interface StyledTabProps {
  "data-flex"?: boolean;
}

const StyledTab = styled(ReactTab)<StyledTabProps>`
  ${tabStyles}
`;

interface TabProps extends Omit<ReactTabProps, "as" | "ref" | "size"> {
  children: React.ReactNode;
  "data-testid"?: string;
  disabled?: boolean;
  id?: string;
  selected?: boolean;
  flex?: boolean;
}

export const Tab = Object.assign(
  ({
    children,
    "data-testid": dataTestId,
    disabled,
    id,
    flex,
    ...props
  }: TabProps) => {
    return (
      <StyledTab
        {...props}
        id={id}
        disabled={disabled}
        data-flex={flex}
        data-testid={dataTestId}
      >
        {children}
      </StyledTab>
    );
  },
  { tabsRole: "Tab" },
);

export const StyledTabPanel = styled(ReactTabPanel)`
  position: relative;
  display: none;
  flex: 1;

  &.${SELECTED_TAB_PANEL} {
    display: flex;
    flex-direction: column;
  }

  @media ${media.lg} {
    position: static;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
}

export const TabPanel = Object.assign(
  ({ children, ...props }: TabPanelProps) => (
    <StyledTabPanel {...props}>{children}</StyledTabPanel>
  ),
  { tabsRole: "TabPanel" },
);

export const StyledTabs = styled(ReactTabs)`
  display: flex;
  flex-direction: column;
`;

interface TabsProps extends Omit<ReactTabsProps, "as" | "size"> {
  size?: "sm" | "md";
}

export const Tabs = ({ size = "md", ...props }: TabsProps) => {
  return <StyledTabs {...props} data-size={size} />;
};

export const TabFooter = styled.div`
  position: sticky;
  margin-top: auto;
  bottom: 0.5rem;

  @media ${media.lg} {
    padding: 0.25rem;
  }
`;
