import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import Spinner from "../Spinner/Spinner";
import StyledButton, {
  type ButtonIconPosition,
  type ButtonSize,
  type ButtonVariant,
  StyledTextWrapper,
} from "./styled";

export {
  buttonSizeStyles,
  buttonVariantStyles,
  commonButtonStyles,
} from "./styled";
export type { ButtonVariant };

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  "data-testid"?: string;
  icon?: ReactSVGComponent;
  iconPosition?: ButtonIconPosition;
  isLoading?: boolean;
  size?: ButtonSize;
  variant?: ButtonVariant;
  fullWidth?: boolean;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    children,
    "data-testid": dataTestId,
    fullWidth = false,
    icon: Icon,
    iconPosition = "start",
    isLoading,
    size = "md",
    type = "button",
    variant = "primary",
    ...htmlProps
  }: ButtonProps,
  ref,
) => {
  return (
    <StyledButton
      {...htmlProps}
      data-testid={dataTestId}
      fullWidth={fullWidth}
      isLoading={isLoading}
      ref={ref}
      size={size}
      type={type}
      variant={variant}
    >
      <StyledTextWrapper>
        {Icon && iconPosition === "start" && (
          <Icon data-testid={dataTestId && `${dataTestId}-icon`} />
        )}
        {children}
        {Icon && iconPosition === "end" && (
          <Icon data-testid={dataTestId && `${dataTestId}-icon`} />
        )}
      </StyledTextWrapper>
      {isLoading && (
        <Spinner data-testid={dataTestId && `${dataTestId}-spinner`} />
      )}
    </StyledButton>
  );
};

export default forwardRef(Button);
