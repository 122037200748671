import {
  SkeletonTheme,
  ToastContainer,
  shouldForwardProp,
} from "@app/design-system";
import "@app/design-system/src/styles/fonts.css";
import { SECOND } from "@kablamo/kerosene";
import { CurrentTimeProvider } from "@kablamo/kerosene-ui";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, useOktaAuth } from "@okta/okta-react";
import * as Sentry from "@sentry/nextjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import getConfig from "next/config";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import { StyleSheetManager } from "styled-components";
import "swiper/css";
import "tippy.js/dist/tippy.css";
import { useIsClient } from "usehooks-ts";
import MapStoreProvider from "../components/map/Map/MapStoreProvider";
import MapPopupGlobalStyles from "../components/popup/SpatialPopup/MapPopupGlobalStyles";
import { FallbackComponent } from "../components/ui/ErrorBoundaryFallbackComponent/ErrorBoundaryFallbackComponent";
import AppThemeProvider from "../components/util/AppThemeProvider/AppThemeProvider";
import AuthBarrier from "../components/util/AuthBarrier/AuthBarrier";
import useAuthAccessToken from "../hooks/useAuthAccessToken";
import useIntercom from "../hooks/useIntercom/useIntercom";
import useSetupAuthInterceptor from "../hooks/useSetupAuthInterceptor";
import GlobalApplicationStyles from "../lib/styled";
import { getDeveloperOptions } from "../utils/getDeveloperOptions/getDeveloperOptions";
import { isEnvironment } from "../utils/isEnvironment";

const SHOW_DEV_TOOLS = globalThis?.location?.search.includes("devtools");
const USE_MSW_QUERY = globalThis?.location?.search.includes("msw");

const isNonProdEnvironment = !isEnvironment("prod");

const USE_MSW_LOCAL_STORAGE =
  getDeveloperOptions()?.options.isMockServiceWorkerEnabled;

if (isNonProdEnvironment && (USE_MSW_QUERY || USE_MSW_LOCAL_STORAGE)) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  import("../mocks/browser").then(({ worker }) =>
    worker.start({
      onUnhandledRequest: "bypass",
      waitUntilReady: true,
    }),
  );
}

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  // eslint-disable-next-line react/no-unused-prop-types -- This is actually used, false positive
  ssrTime: number;
};

const {
  publicRuntimeConfig: {
    NEXT_APP_DOMAIN,
    NEXT_OKTA_CLIENT_ID,
    NEXT_OKTA_ISSUER,
  },
} = getConfig();

// Make sure we have these fields available - they're not visible at compile time
const isOktaAvailable = !!(
  typeof window !== "undefined" &&
  NEXT_OKTA_CLIENT_ID &&
  NEXT_OKTA_ISSUER &&
  NEXT_APP_DOMAIN
);
const oktaAuth =
  isOktaAvailable &&
  new OktaAuth({
    clientId: NEXT_OKTA_CLIENT_ID,
    issuer: NEXT_OKTA_ISSUER,
    redirectUri: NEXT_APP_DOMAIN,
    scopes: ["email", "profile", "openid", "offline_access"],
  });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * SECOND,
      // Disable retries globally because majority is either from server errors
      // or 404 or similar, where retry will not improve the result and the
      // lack of feedback to the user is disorienting.
      retry: false,
    },
  },
});

function App(props: AppPropsWithLayout) {
  return (
    <StyleSheetManager
      enableVendorPrefixes
      shouldForwardProp={shouldForwardProp}
    >
      <AppWithMount {...props} />
    </StyleSheetManager>
  );
}

// AppWithMount prevents the app from rendering until the the component
// has been mounted. This is necessary because the we need SSR to pass the
// TENANT context. But the authentication check, and some other components
// are not SSR compatible.
//
// If we can move all calls that interact with browser apis inside useEffect's
// then we can remove this. This includes libraries like Okta that also call
// the window object directly
function AppWithMount(props: AppPropsWithLayout) {
  const isClient = useIsClient();

  if (!isClient) return null;

  return <AppWithProviders {...props} />;
}

function AppWithProviders(props: AppPropsWithLayout) {
  const { replace } = useRouter();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    await replace(toRelativeUrl(originalUri, NEXT_APP_DOMAIN));
  };

  return (
    <AppThemeProvider isNonProdEnvironment={isNonProdEnvironment}>
      <MapPopupGlobalStyles />
      <GlobalApplicationStyles />
      <ToastContainer />
      <CurrentTimeProvider ssrTime={props.ssrTime}>
        <SkeletonTheme>
          <Sentry.ErrorBoundary fallback={FallbackComponent}>
            {isOktaAvailable && oktaAuth && (
              <Security
                oktaAuth={oktaAuth}
                restoreOriginalUri={restoreOriginalUri}
              >
                <AppWithAuth {...props} />
              </Security>
            )}
          </Sentry.ErrorBoundary>
        </SkeletonTheme>
      </CurrentTimeProvider>
    </AppThemeProvider>
  );
}

function AppWithAuth({ Component }: AppPropsWithLayout) {
  const { authState, oktaAuth: auth } = useOktaAuth();
  const authAccessToken = useAuthAccessToken();
  useSetupAuthInterceptor();
  const [error, setError] = useState();
  useIntercom();

  // handleLoginRedirect does not work properly with StrictMode, so using a ref to ensure it is only called once
  const handleLoginRedirectPromise = React.useRef<Promise<void>>();
  useEffect(() => {
    if (auth.isLoginRedirect()) {
      handleLoginRedirectPromise.current ??= auth
        .handleLoginRedirect()
        .catch((e) => {
          setError(e);
          Sentry.captureException(e);
        })
        .finally(() => {
          handleLoginRedirectPromise.current = undefined;
        });
    } else if (!authAccessToken) {
      auth.setOriginalUri(window.location.href);
      void auth.signInWithRedirect();
    }
  }, [authAccessToken, auth]);

  const isLoading = !authAccessToken && !authState?.error;

  // https://nextjs.org/docs/basic-features/layouts#with-typescript
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <AuthBarrier error={error} isLoading={isLoading}>
      <QueryClientProvider client={queryClient}>
        {SHOW_DEV_TOOLS && <ReactQueryDevtools />}
        <MapStoreProvider>{getLayout(<Component />)}</MapStoreProvider>
      </QueryClientProvider>
    </AuthBarrier>
  );
}

App.getInitialProps = () => {
  // Forcing SSR to access publicRuntimeConfig on every page
  return {
    ssrTime: Date.now(),
  };
};

export default App;
