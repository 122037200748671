import { css } from "styled-components";
import { focusStyles, weakStyles } from "../Button/states";

export const tabListStyles = css`
  display: flex;
  padding: 0.1875rem;
  gap: 0.25rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  ${weakStyles.background.base}
  ${weakStyles.border.base}

  &[data-size="sm"] li, &[data-size="sm"] a {
    padding: 0.25rem 0.75rem;
  }

  &[data-size="md"] li,
  &[data-size="md"] a {
    padding: 0.375rem 0.75rem;
  }
`;

export const tabStyles = css`
  display: flex;
  gap: 0.375rem;
  padding: 0.375rem 0.75rem;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${(p) => p.theme.typography.variants.buttonDefault}
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  text-align: center;
  transition:
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  cursor: pointer;

  &[data-flex="true"] {
    flex: 1;
  }

  &[aria-selected="true"],
  &[aria-current="true"] {
    background-color: ${(p) => p.theme.colors.neutrals.background};
    color: ${(p) => p.theme.colors.neutrals.text};
    box-shadow: ${(p) => p.theme.boxShadows.sm};
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  &:hover:not([aria-selected="true"], [aria-current="true"]) {
    ${weakStyles.background.hover}
  }

  &:focus-visible {
    ${focusStyles("ring")}
    z-index: 1;
  }

  &:active:not([aria-selected="true"], [aria-current="true"]) {
    ${weakStyles.background.active}
  }
`;
