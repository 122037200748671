import styled from "styled-components";
import { tabListStyles, tabStyles } from "./styled";

export const LinkTabList = styled.div`
  ${tabListStyles}
`;

export const LinkTab = styled.a`
  ${tabStyles}
`;
