import { isDeveloperOptions } from "../../components/util/DeveloperOptionsProvider/DeveloperOptionsProvider";
import { LocalStorageKeys } from "../../config/storage";

/**
 * Use this function if you want to get the point-in-time value of the developer
 * options stored in localStorage. This is useful for things like deciding
 * whether to initialise the mock service worker when the application starts.
 */
export function getDeveloperOptions() {
  if (!globalThis.window) return null;
  const value = window.localStorage.getItem(LocalStorageKeys.DEVELOPER_OPTIONS);
  if (!value) return null;
  try {
    const developerOptions = JSON.parse(value);
    if (!isDeveloperOptions(developerOptions)) return null;

    return developerOptions;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Unable to parse developer options: ", error);
    return null;
  }
}
