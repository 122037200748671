import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import { Close } from "../../icons";
import buttonReset from "../../lib/styled/buttonReset";
import type { TypographySize } from "../../theme/constants";
import media from "../../theme/media";
import { focusStyles, mediumStyles, weakStyles } from "../Button/states";
import { commonButtonStyles } from "../Button/styled";

export const inputContainerCssProperties = {
  gap: "--input-gap",
  hasIconPaddingX: "--input-has-icon-padding-x",
  iconSize: "--input-icon-size",
  paddingX: "--input-padding-x",
  paddingY: "--input-padding-y",
};

export type ValidationStatus = "error" | "success";

export const inputValidationStyles: Record<
  ValidationStatus,
  ReturnType<typeof css>
> = {
  error: css`
    && {
      border-color: ${(p) => p.theme.colors.error.borderHover};
    }
  `,
  success: css`
    && {
      border-color: ${(p) => p.theme.colors.success.border};
    }
  `,
};

export interface CommonInputStylesProps {
  hasIconEnd?: boolean;
  hasIconStart?: boolean;
  validationStatus?: ValidationStatus;
}

export const commonInputStyles = css<CommonInputStylesProps>`
  display: block;
  width: 100%;
  padding: var(${inputContainerCssProperties.paddingY})
    var(${inputContainerCssProperties.paddingX});
  flex-shrink: 0;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  ${weakStyles.background.base}
  ${mediumStyles.border.base}
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  transition:
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    border-color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  ${(p) =>
    p.hasIconEnd &&
    css`
      padding-right: var(${inputContainerCssProperties.hasIconPaddingX});
    `}

  ${(p) =>
    p.hasIconStart &&
    css`
      padding-left: var(${inputContainerCssProperties.hasIconPaddingX});
    `}

  &::placeholder {
    color: ${(p) => p.theme.colors.neutrals.textPlaceholder};
  }

  &:hover:not(:is(:disabled, [data-disabled="true"])) {
    ${weakStyles.background.hover}
    ${mediumStyles.border.hover}
  }

  &:focus-visible,
  &:focus-within {
    ${focusStyles("ring")}
  }

  &:is(:disabled, [data-disabled="true"]) {
    ${weakStyles.background.disabled}
    ${mediumStyles.border.disabled}
    cursor: not-allowed;
  }

  &:is(:disabled, [data-disabled="true"])::placeholder {
    color: ${(p) => p.theme.colors.neutrals.textPlaceholderDisabled};
  }

  ${(p) => p.validationStatus && inputValidationStyles[p.validationStatus]}
`;

const StyledStartAdornment = styled.div`
  position: absolute;
  pointer-events: none;
`;

const StyledEndAdornment = styled.div`
  position: absolute;
  pointer-events: none;
`;

const StyledClearButton = styled.button`
  ${buttonReset}
  ${commonButtonStyles}
  position: absolute;
  color: ${(p) => p.theme.colors.neutrals.text};
`;

export type InputSize = "xs" | "sm" | "md" | "xl";

type InputSizeConfig = {
  gap: number;
  padding: [number, number];
  svgSize: number;
  typographySize: TypographySize;
};

type InputSizeMap = Record<InputSize, InputSizeConfig>;

const inputSizeMap: InputSizeMap = {
  xs: {
    gap: 2,
    padding: [3, 8],
    svgSize: 16,
    typographySize: "bodyDefault",
  },
  sm: {
    gap: 4,
    padding: [5, 12],
    svgSize: 20,
    typographySize: "bodyDefault",
  },
  md: {
    gap: 4,
    padding: [9, 12],
    svgSize: 20,
    typographySize: "bodyDefault",
  },
  xl: {
    gap: 4,
    padding: [9, 12],
    svgSize: 24,
    typographySize: "h2",
  },
};

const sizePropertyStyles = css<StyledInputContainerProps>`
  svg {
    height: var(${inputContainerCssProperties.iconSize});
    width: var(${inputContainerCssProperties.iconSize});
  }

  ${StyledEndAdornment} {
    top: 50%;
    transform: translateY(-50%);
    right: calc(var(${inputContainerCssProperties.gap}) * 2);
  }

  ${StyledStartAdornment} {
    top: 50%;
    transform: translateY(-50%);
    left: calc(var(${inputContainerCssProperties.gap}) * 2);
  }

  ${StyledClearButton} {
    top: 50%;
    transform: translateY(-50%);
    right: calc(var(${inputContainerCssProperties.gap}) * 2);
  }
`;

export const getInputSizeProperties = (config: InputSizeConfig) => {
  const { gap, padding, svgSize } = config;
  return css`
    ${`${inputContainerCssProperties.gap}: ${gap / 16}rem;`}
    ${`${inputContainerCssProperties.hasIconPaddingX}: ${
      (gap * 3 + svgSize) / 16
    }rem;`}
  ${`${inputContainerCssProperties.iconSize}: ${svgSize / 16}rem;`}
  ${`${inputContainerCssProperties.paddingY}: ${padding[0] / 16}rem;`}
  ${`${inputContainerCssProperties.paddingX}: ${padding[1] / 16}rem;`}
  ${(p) => p.theme.typography.variants[config.typographySize]}
  `;
};

const containerValidationStyles: Record<
  ValidationStatus,
  ReturnType<typeof css>
> = {
  error: css`
    svg {
      color: ${(p) => p.theme.colors.error.iconHover};
    }
  `,
  success: css`
    svg {
      color: ${(p) => p.theme.colors.success.icon};
    }
  `,
};

interface StyledInputContainerProps {
  $disabled: boolean;
  size: InputSize;
  validationStatus?: ValidationStatus;
}

const StyledInputContainer = styled.div<StyledInputContainerProps>`
  position: relative;
  display: flex;

  & :is(${StyledStartAdornment}, ${StyledEndAdornment}, ${StyledClearButton}) {
    color: ${(p) => p.theme.colors.neutrals.iconWeak};
  }

  ${(p) => p.validationStatus && containerValidationStyles[p.validationStatus]};

  ${sizePropertyStyles}

  ${getInputSizeProperties(inputSizeMap.md)}

  ${(p) =>
    p.$disabled &&
    css`
      ${StyledStartAdornment}, ${StyledEndAdornment} {
        color: ${p.theme.colors.neutrals.iconDisabled};
      }
    `}

  @media ${media.lg} {
    ${(p) => getInputSizeProperties(inputSizeMap[p.size])}
  }
`;

export type InputContainerProps = {
  children?: React.ReactNode;
  className?: string;
  "data-testid"?: string;
  disabled?: boolean;
  isClearable?: boolean;
  iconEnd?: ReactSVGComponent;
  iconStart?: ReactSVGComponent;
  onClearClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Use `xs` within a `FieldRow` inside drawers and popovers.
   * Use `sm` for filtering and in other full-width but narrow places.
   * Use `md` within forms.
   */
  size?: InputSize;
  validationStatus?: ValidationStatus;
};

const InputContainer: ForwardRefRenderFunction<
  HTMLDivElement,
  InputContainerProps
> = (
  {
    children,
    className,
    "data-testid": dataTestId,
    disabled = false,
    isClearable,
    iconEnd: IconEnd,
    iconStart: IconStart,
    onClearClick,
    size = "md",
    validationStatus,
    ...props
  }: InputContainerProps,
  ref,
) => {
  return (
    <StyledInputContainer
      className={className}
      $disabled={disabled}
      validationStatus={validationStatus}
      data-testid={dataTestId}
      size={size}
      ref={ref}
      {...props}
    >
      {IconStart && (
        <StyledStartAdornment>
          <IconStart data-testid={dataTestId && `${dataTestId}-icon-start`} />
        </StyledStartAdornment>
      )}
      {children}
      {isClearable && (
        <StyledClearButton
          aria-label="Clear input"
          onClick={onClearClick}
          type="button"
        >
          <Close />
        </StyledClearButton>
      )}
      {IconEnd && (
        <StyledEndAdornment>
          <IconEnd data-testid={dataTestId && `${dataTestId}-icon-end`} />
        </StyledEndAdornment>
      )}
    </StyledInputContainer>
  );
};

export default forwardRef(InputContainer);
