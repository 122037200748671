import styled, { css } from "styled-components";
import type { TypographySize } from "../../theme";
import type { StatusVariant } from "../../types/design-system";
import TypographyStatusIcon from "../StatusIcon/TypographyStatusIcon";
import { Tooltip } from "../Tooltip/Tooltip";

const statusVariantStyleMap: Record<StatusVariant, ReturnType<typeof css>> = {
  danger: css`
    color: ${(p) => p.theme.colors.danger.textOnBackground};
  `,
  error: css`
    color: ${(p) => p.theme.colors.error.text};
  `,
  info: css`
    color: ${(p) => p.theme.colors.neutrals.text};
  `,
  progress: css`
    color: ${(p) => p.theme.colors.informative.text};
  `,
  success: css`
    color: ${(p) => p.theme.colors.success.text};
  `,
  warning: css`
    color: ${(p) => p.theme.colors.warning.text};
  `,
};

interface StyledStatusMessageProps {
  size: TypographySize;
  variant: StatusVariant;
}

const StyledStatusMessage = styled.div<StyledStatusMessageProps>`
  display: flex;
  align-items: start;
  gap: 4px;

  ${(p) => statusVariantStyleMap[p.variant]}
  ${(p) => p.theme.typography.variants[p.size]}
`;

export interface StatusMessageProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  size?: TypographySize;
  tooltip?: React.ReactNode;
  variant: StatusVariant;
}

const StatusMessage = ({
  children,
  "data-testid": dataTestId,
  size = "bodyDefault",
  tooltip,
  variant,
}: StatusMessageProps) => {
  const content = (
    <StyledStatusMessage size={size} variant={variant}>
      <TypographyStatusIcon size={size} status={variant} />
      <div data-testid={dataTestId}>{children}</div>
    </StyledStatusMessage>
  );

  if (tooltip) {
    return <Tooltip message={tooltip}>{content}</Tooltip>;
  }

  return content;
};

export default StatusMessage;
