import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import buttonReset from "../../lib/styled/buttonReset";
import { commonButtonStyles } from "../Button/Button";
import { ghost, ghostWeak, secondary } from "../Button/styled";
import { Tooltip } from "../Tooltip/Tooltip";

export const ICON_BUTTON_SIZES = ["xs", "sm", "md"] as const;

export type IconButtonSize = (typeof ICON_BUTTON_SIZES)[number];

type IconButtonSizeStyleMap = Record<IconButtonSize, ReturnType<typeof css>>;

const primary = css`
  color: ${(p) => p.theme.colors.primary.iconOnBackground};
  background-color: ${(p) => p.theme.colors.primary.backgroundMedium};
  border-color: transparent;

  &:hover:not(:disabled) {
    background-color: ${(p) => p.theme.colors.primary.backgroundMediumHover};
  }

  &:active:not(:disabled) {
    background-color: ${(p) => p.theme.colors.selected.backgroundWeak};
  }

  &:disabled {
    color: ${(p) => p.theme.colors.neutrals.iconDisabled};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  }
`;

const inverse = css`
  color: ${(p) => p.theme.colors.fireStatus.iconOnBackground};
  background-color: transparent;
  border-color: transparent;

  &:hover:not(:disabled) {
    background-color: ${(p) => p.theme.colors.fireStatus.buttonBackgroundHover};
  }

  &:active:not(:disabled) {
    background-color: ${(p) =>
      p.theme.colors.fireStatus.iconDisabledOnBackground};
  }

  &:disabled {
    color: ${(p) => p.theme.colors.neutrals.textInverseDisabled};
    background-color: ${(p) => p.theme.colors.fireStatus.buttonBackgroundHover};
  }
`;

const error = css`
  color: ${(p) => p.theme.colors.error.text};
  background-color: transparent;
  border-color: ${(p) => p.theme.colors.error.border};

  &:hover:not(:disabled) {
    background-color: ${(p) => p.theme.colors.error.backgroundWeakHover};
  }

  &:active:not(:disabled) {
    background-color: ${(p) => p.theme.colors.error.backgroundMedium};
  }

  &:disabled {
    background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
    border: none;
    color: ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
  }
`;

const iconButtonVariantStyles = {
  primary,
  secondary,
  ghost,
  ghostWeak,
  inverse,
  error,
};

export const ICON_BUTTON_VARIANTS = [
  "primary",
  "secondary",
  "ghost",
  "ghostWeak",
  "inverse",
  "error",
] as const;

export type IconButtonVariant = (typeof ICON_BUTTON_VARIANTS)[number];

const iconButtonSizeStyles: IconButtonSizeStyleMap = {
  xs: css`
    width: 1.5rem;
    height: 1.5rem;

    svg {
      height: 1rem;
      width: 1rem;
    }
  `,
  sm: css`
    width: 2rem;
    height: 2rem;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  `,
  md: css`
    width: 2.25rem;
    height: 2.25rem;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  `,
};

interface StyledIconButtonProps {
  size: IconButtonSize;
  variant: IconButtonVariant;
}

const StyledIconButton = styled.button<StyledIconButtonProps>`
  ${buttonReset}
  ${commonButtonStyles}
  ${(p) => iconButtonVariantStyles[p.variant]}
  ${(p) => iconButtonSizeStyles[p.size]}
`;

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  "data-testid"?: string;
  icon: ReactSVGComponent;
  label: string;
  /**
   * Avoid using this prop unless in special cases where you are going to be
   * providing your own tooltip externally.
   */
  noTooltip?: boolean;
  size?: IconButtonSize;
  variant?: IconButtonVariant;
}

const IconButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  IconButtonProps
> = (
  {
    "data-testid": dataTestId,
    icon: Icon,
    label,
    noTooltip,
    size = "md",
    type = "button",
    variant = "primary",
    ...props
  }: IconButtonProps,
  ref,
) => {
  const button = (
    <StyledIconButton
      aria-label={label}
      ref={ref}
      size={size}
      type={type}
      variant={variant}
      data-testid={dataTestId}
      {...props}
    >
      <Icon data-testid={dataTestId && `${dataTestId}-icon`} />
    </StyledIconButton>
  );

  if (noTooltip) {
    return button;
  }

  return (
    <Tooltip delay message={label}>
      {button}
    </Tooltip>
  );
};

export default forwardRef(IconButton);
