import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import type { FieldError as HookFormFieldError } from "react-hook-form";
import styled from "styled-components";
import RadioButton from "../RadioButton/RadioButton";
import StatusMessage from "../StatusMessage/StatusMessage";
import Text from "../Text/Text";
import RadioButtonGroupProvider, {
  useRadioButtonGroup,
} from "./RadioButtonGroupProvider";

const StyledRadioButtonGroup = styled.fieldset`
  display: grid;
  gap: 0.5rem;
`;

const StyledRadios = styled.div`
  display: grid;
  gap: 0.25rem;
`;

const StyledLegend = styled.legend`
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${(p) => p.theme.typography.variants.label}
  margin-bottom: 0.5rem;
`;

export interface RadioButtonGroupProps {
  children?: React.ReactNode;
  error?: HookFormFieldError | string;
  hint?: React.ReactNode;
  label?: React.ReactNode;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLFieldSetElement>;
  onChange: (value: string) => void;
  value: string;
}

const RadioButtonGroup: ForwardRefRenderFunction<
  HTMLFieldSetElement,
  RadioButtonGroupProps
> = (
  {
    children,
    error,
    hint,
    label,
    name,
    onBlur,
    onChange: _onChange,
    value,
  }: RadioButtonGroupProps,
  ref,
) => {
  let errorMessage: string | undefined;
  if (typeof error === "string") {
    errorMessage = error;
  } else if (error) {
    errorMessage = error.message;
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _onChange(event.currentTarget.value);
  };

  return (
    <RadioButtonGroupProvider value={value} name={name} onChange={onChange}>
      <StyledRadioButtonGroup onBlur={onBlur} ref={ref}>
        {label && <StyledLegend>{label}</StyledLegend>}
        {children && <StyledRadios>{children}</StyledRadios>}
        {hint && (
          <Text size="footnote" variant="weak">
            {hint}
          </Text>
        )}
        {errorMessage && (
          <StatusMessage variant="error">{errorMessage}</StatusMessage>
        )}
      </StyledRadioButtonGroup>
    </RadioButtonGroupProvider>
  );
};

interface RadioButtonGroupItemProps {
  "data-testid"?: string;
  disabled?: boolean;
  hint?: React.ReactNode;
  label: string;
  value: string;
}

const RadioButtonGroupItem = ({
  "data-testid": dataTestId,
  disabled,
  hint,
  label,
  value,
}: RadioButtonGroupItemProps) => {
  const field = useRadioButtonGroup();

  return (
    <RadioButton
      checked={field.value === value}
      data-testid={dataTestId}
      disabled={disabled}
      hint={hint}
      label={label}
      onChange={field.onChange}
      name={field.name}
      value={value}
    />
  );
};

export default Object.assign(forwardRef(RadioButtonGroup), {
  Item: RadioButtonGroupItem,
});
