import { forwardRef, type ForwardRefRenderFunction } from "react";
import type { FieldError as HookFormFieldError } from "react-hook-form";
import styled, { css } from "styled-components";
import StatusMessage from "../StatusMessage/StatusMessage";
import Text from "../Text/Text";

const StyledField = styled.div`
  display: grid;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.bodyDefault}
`;

const StyledOptional = styled.span`
  color: ${(p) => p.theme.colors.neutrals.textDisabled};
`;

interface StyledControlProps {
  constrain?: boolean;
}

const StyledControl = styled.div<StyledControlProps>`
  display: grid;
  gap: 0.25rem;

  ${(p) =>
    p.constrain &&
    css`
      max-width: 22rem;
    `}
`;

export interface FieldProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  constrain?: boolean;
  "data-testid"?: string;
  disabled?: boolean;
  error?: HookFormFieldError | string;
  hint?: React.ReactNode;
  htmlFor?: string;
  id?: string;
  isOptional?: boolean;
  label: React.ReactNode;
}

const Field: ForwardRefRenderFunction<HTMLDivElement, FieldProps> = (
  {
    children,
    constrain,
    "data-testid": dataTestId,
    disabled,
    error,
    hint,
    htmlFor,
    id,
    isOptional,
    label,
    ...props
  }: FieldProps,
  ref,
) => {
  let errorMessage: string | undefined;
  if (typeof error === "string") {
    errorMessage = error;
  } else if (error) {
    errorMessage = error.message;
  }

  return (
    <StyledField {...props} ref={ref} data-testid={dataTestId}>
      <div>
        <Text
          as="label"
          htmlFor={htmlFor}
          id={id}
          size="label"
          variant={disabled ? "disabled" : "weak"}
          data-testid={dataTestId && `${dataTestId}-label`}
        >
          {label}
          {isOptional && <StyledOptional>(optional)</StyledOptional>}
        </Text>
      </div>
      <StyledControl constrain={constrain}>{children}</StyledControl>
      {hint && (
        <Text size="footnote" variant="weak">
          {hint}
        </Text>
      )}
      {errorMessage && (
        <StatusMessage
          data-testid={dataTestId && `${dataTestId}-error`}
          size="footnote"
          variant="error"
        >
          {errorMessage}
        </StatusMessage>
      )}
    </StyledField>
  );
};

export default forwardRef(Field);
