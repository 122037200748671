import type { ColorTheme, NeutralColorTheme } from "./colors";
import palette from "./palette";
import { colorsToPropertyDeclarations } from "./transformColors";

export const defaultSurfaceNeutrals = {
  // Matches background
  surface: palette.white100,

  // Matches borderWeak
  surfaceBorder: palette.charcoal100,

  // Matches borderExtraWeak
  surfaceDivider: palette.charcoal50,

  background: palette.white100,
  backgroundDisabled: palette.grey50,

  backgroundSkeleton: palette.charcoal50,
  backgroundSkeletonHighlight: palette.white25,

  backgroundWeak: palette.grey50,
  backgroundWeakHover: palette.charcoal50,
  backgroundWeakActive: palette.charcoal100,

  backgroundMedium: palette.charcoal50,
  backgroundMediumHover: palette.charcoal100,
  backgroundMediumActive: palette.charcoal200,

  backgroundMediumSkeleton: palette.charcoal200,
  backgroundMediumSkeletonHighlight: palette.white25,

  backgroundStrong: palette.charcoal100,
  backgroundStrongHover: palette.charcoal200,
  backgroundStrongActive: palette.charcoal300,

  backgroundExtraStrong: palette.charcoal200,
  backgroundExtraStrongDisabled: palette.charcoal100,

  backgroundInverse: palette.black100,
  backgroundInverseSkeleton: palette.charcoal600,
  backgroundInverseSkeletonHighlight: palette.white10,

  borderExtraWeak: palette.charcoal50,
  borderExtraWeakHover: palette.charcoal100,
  borderExtraWeakActive: palette.charcoal200,

  borderWeak: palette.charcoal100,
  borderWeakHover: palette.charcoal200,
  borderWeakActive: palette.charcoal300,

  borderMedium: palette.charcoal200,
  borderMediumHover: palette.charcoal300,
  borderMediumActive: palette.charcoal400,

  borderStrong: palette.charcoal300,
  borderStrongHover: palette.charcoal400,
  borderStrongActive: palette.charcoal500,

  borderDisabled: palette.charcoal100,

  backgroundSelected: palette.charcoal900,
  backgroundSelectedDisabled: palette.charcoal300,

  borderSelected: palette.black100,

  borderFocus: palette.charcoal400,

  text: palette.charcoal900,
  textWeak: palette.charcoal600,
  textWeakHover: palette.charcoal800,
  textPlaceholder: palette.charcoal500,
  textPlaceholderDisabled: palette.charcoal200,
  textDisabled: palette.charcoal200,
  textControlDisabled: palette.charcoal400,
  textInverse: palette.white100,
  textInverseWeak: palette.white75,
  textInverseDisabled: palette.white25,

  iconWeak: palette.charcoal600,
  iconDisabled: palette.charcoal200,
  backdrop: palette.black95,
  backdropWeak: palette.black75,
} satisfies NeutralColorTheme;

export const weakSurfaceNeutrals: NeutralColorTheme = {
  ...defaultSurfaceNeutrals,

  surface: defaultSurfaceNeutrals.backgroundWeak,

  backgroundWeak: palette.white100,
  backgroundWeakHover: palette.grey50,
  backgroundWeakActive: palette.charcoal50,

  backgroundMedium: palette.white100,
  backgroundMediumHover: palette.grey50,
  backgroundMediumActive: palette.charcoal50,
};

export const mediumSurfaceNeutrals: NeutralColorTheme = {
  ...defaultSurfaceNeutrals,

  surface: defaultSurfaceNeutrals.backgroundMedium,

  backgroundWeak: palette.white100,
  backgroundWeakHover: palette.grey50,
  backgroundWeakActive: palette.charcoal50,

  backgroundMedium: palette.white100,
  backgroundMediumHover: palette.grey50,
  backgroundMediumActive: palette.charcoal50,
};

export const strongSurfaceNeutrals: NeutralColorTheme = {
  ...defaultSurfaceNeutrals,

  surface: defaultSurfaceNeutrals.backgroundStrong,

  backgroundWeak: palette.white100,
  backgroundWeakHover: palette.grey50,
  backgroundWeakActive: palette.charcoal50,

  backgroundMedium: palette.white100,
  backgroundMediumHover: palette.grey50,
  backgroundMediumActive: palette.charcoal50,

  borderWeak: palette.charcoal200,
  borderWeakHover: palette.charcoal300,
  borderWeakActive: palette.charcoal400,
};

export const lightColors: ColorTheme = {
  neutrals: defaultSurfaceNeutrals,
  primary: {
    text: palette.mint600,
    textHover: palette.mint700,
    textOnBackground: palette.black100,
    backgroundExtraWeak: palette.mint25,
    backgroundExtraWeakHover: palette.mint50,
    backgroundExtraWeakPressed: palette.mint100,
    backgroundWeak: palette.mint50,
    backgroundWeakHover: palette.mint100,
    backgroundWeakPressed: palette.mint200,
    backgroundMedium: palette.mint200,
    backgroundMediumHover: palette.mint300,
    backgroundMediumPressed: palette.mint400,
    backgroundStrong: palette.mint300,
    border: palette.mint300,
    borderHover: palette.mint300,
    borderExtraWeak: palette.mint75,
    borderExtraWeakHover: palette.mint100,
    borderExtraWeakPressed: palette.mint200,
    icon: palette.mint400,
    iconHover: palette.mint500,
    iconOnBackground: palette.black100,
  },
  success: {
    text: palette.green600,
    textHover: palette.green700,
    textOnBackground: palette.black100,
    textOnBackgroundStrong: palette.white100,
    backgroundWeak: palette.green50,
    backgroundWeakHover: palette.green100,
    backgroundMedium: palette.green200,
    backgroundMediumHover: palette.green300,
    backgroundStrong: palette.green500,
    backgroundStrongHover: palette.green600,
    backgroundStrongActive: palette.green700,
    border: palette.green500,
    borderHover: palette.green600,
    icon: palette.green500,
    iconHover: palette.green600,
    iconOnBackground: palette.black100,
    iconOnBackgroundStrong: palette.white100,
  },
  warning: {
    text: palette.orange500,
    textHover: palette.orange600,
    textOnBackground: palette.black100,
    textOnBackgroundStrong: palette.white100,
    backgroundWeak: palette.orange50,
    backgroundWeakHover: palette.orange100,
    backgroundMedium: palette.orange200,
    backgroundMediumHover: palette.orange300,
    backgroundStrong: palette.orange600,
    backgroundStrongHover: palette.orange700,
    backgroundStrongActive: palette.orange800,
    border: palette.orange400,
    borderHover: palette.orange500,
    icon: palette.orange500,
    iconHover: palette.orange600,
    iconOnBackground: palette.black100,
    iconOnBackgroundStrong: palette.white100,
  },
  danger: {
    backgroundWeak: "#fef1f0",
    icon: palette.red500,
    textOnBackground: palette.charcoal900,
  },
  error: {
    text: palette.red600,
    textHover: palette.red700,
    textOnBackground: palette.black100,
    textOnBackgroundStrong: palette.white100,
    backgroundWeak: palette.red50,
    backgroundWeakHover: palette.red100,
    backgroundMedium: palette.red200,
    backgroundMediumHover: palette.red300,
    backgroundStrong: palette.red600,
    backgroundStrongHover: palette.red700,
    backgroundStrongActive: palette.red800,
    border: palette.red500,
    borderHover: palette.red600,
    icon: palette.red600,
    iconHover: palette.red600,
    iconOnBackground: palette.black100,
    iconOnBackgroundStrong: palette.white100,
  },
  selected: {
    text: palette.mint500,
    textHover: palette.mint600,
    textOnBackground: palette.black100,
    textOnBackgroundStrong: palette.white100,
    backgroundWeak: palette.mint50,
    backgroundWeakHover: palette.mint100,
    backgroundMedium: palette.mint200,
    backgroundMediumHover: palette.mint300,
    backgroundStrong: palette.mint400,
    backgroundStrongHover: palette.mint500,
    border: palette.mint300,
    borderHover: palette.mint400,
    icon: palette.mint400,
    iconHover: palette.mint500,
    iconOnBackground: palette.black100,
    iconOnBackgroundStrong: palette.white100,
  },
  fireStatus: {
    going: {
      background: palette.red600,
      backgroundSelected: palette.red700,
      backgroundWeak: palette.red100,
      icon: palette.red600,
    },
    contained: {
      background: palette.orange400,
      backgroundSelected: palette.orange500,
      backgroundWeak: palette.orange100,
      icon: palette.orange400,
    },
    beingControlled: {
      background: "#F0E641",
      backgroundSelected: palette.yellow300,
      backgroundWeak: "#EFF1BF",
      icon: palette.yellow200,
    },
    controlled: {
      background: palette.mint300,
      backgroundSelected: palette.mint400,
      backgroundWeak: palette.mint50,
      icon: palette.mint300,
    },
    patrol: {
      background: palette.manualBlue300,
      backgroundSelected: palette.manualBlue400,
      backgroundWeak: palette.manualBlue50,
      icon: palette.manualBlue300,
    },
    textOnBackground: palette.white100,
    textOnBackgroundWeak: palette.black100,
    textDisabledOnBackground: palette.white25,
    iconOnBackground: palette.white100,
    iconDisabledOnBackground: palette.white20,
    borderOnBackground: palette.white75,
    borderOnBackgroundActive: palette.white100,
    borderOnBackgroundHover: palette.white100,
    buttonBackgroundHover: palette.white10,
    borderDisabledOnBackground: palette.white25,
  },
  mapping: {
    icon: palette.red500,
    socialShadow: palette.black100,
    socialActiveFill: palette.black100,
    socialFill: palette.pink600,
    socialStroke: palette.white100,
    redMap: palette.red500,
  },
  decorative: {
    neutralBackground: palette.charcoal600,
    neutralBackgroundHover: palette.charcoal700,
    endorsedBackground: palette.mint600,
    endorsedBackgroundHover: palette.mint700,
    manualBackground: palette.manualBlue600,
    manualBackgroundHover: palette.manualBlue700,
    automatedBackground: palette.pink600,
    automatedBackgroundHover: palette.pink700,
    manualBackgroundWeak: palette.manualBlue50,
    manualText: palette.manualBlue600,
    manualBackgroundWeakHover: palette.manualBlue100,
    manualBorderMedium: palette.manualBlue200,
    automatedBackgroundWeak: palette.pink50,
    automatedBackgroundWeakHover: palette.pink100,
    automatedText: palette.pink800,
  },
  social: {
    strongBackground: palette.navy700,
    strongBackgroundSkeleton: palette.navy500,
    strongBackgroundSkeletonHighlight: palette.white10,
    link: palette.socialBlue,
  },
  informative: {
    backgroundStrong: palette.navy700,
    backgroundStrongHover: palette.navy800,
    backgroundStrongActive: palette.navy900,
    backgroundWeak: palette.navy50,
    backgroundWeakHover: palette.navy100,
    borderWeak: palette.navy100,
    border: palette.navy700,
    icon: palette.navy700,
    text: palette.navy700,
    textHover: palette.navy800,
  },
};

export const lightColorPropertyDeclarations =
  colorsToPropertyDeclarations(lightColors);

export const defaultSurfaceOverrides = colorsToPropertyDeclarations({
  neutrals: defaultSurfaceNeutrals,
});

export const weakSurfaceOverrides = colorsToPropertyDeclarations({
  neutrals: weakSurfaceNeutrals,
});

export const mediumSurfaceOverrides = colorsToPropertyDeclarations({
  neutrals: mediumSurfaceNeutrals,
});

export const strongSurfaceOverrides = colorsToPropertyDeclarations({
  neutrals: strongSurfaceNeutrals,
});
