import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import media from "../../theme/media";
import HeaderLinkTabs from "../HeaderLinkTabs/HeaderLinkTabs";
import { InverseSkeletonTheme } from "../SkeletonTheme/SkeletonTheme";

interface StyledNavigationHeaderProps {
  hasActions: boolean;
  hasTabs: boolean;
}

interface GridTemplate {
  area: string;
  column: string;
}

enum GridArea {
  ACTIONS = "actions",
  NAV = "nav",
  NOTIFICATIONS = "notifications",
  SEARCH = "search",
  TABS = "tabs",
  TITLE = "title",
}

const gridMap: Record<GridArea, GridTemplate> = {
  actions: {
    area: GridArea.ACTIONS,
    column: "auto",
  },
  nav: {
    area: GridArea.NAV,
    column: "auto",
  },
  notifications: {
    area: GridArea.NOTIFICATIONS,
    column: "auto",
  },
  search: {
    area: GridArea.SEARCH,
    column: "auto",
  },
  tabs: {
    area: GridArea.TABS,
    column: "",
  },
  title: {
    area: GridArea.TITLE,
    column: "minmax(0, 1fr)",
  },
};

const lgGridMap: Record<GridArea, GridTemplate> = {
  actions: {
    area: GridArea.ACTIONS,
    column: "auto",
  },
  nav: {
    area: GridArea.NAV,
    column: "auto",
  },
  notifications: {
    area: GridArea.NOTIFICATIONS,
    column: "auto",
  },
  search: {
    area: GridArea.SEARCH,
    column: "auto",
  },
  tabs: {
    area: GridArea.TABS,
    column: "minmax(0, min-content)",
  },
  title: {
    area: GridArea.TITLE,
    column: "minmax(0, 1fr)",
  },
};

const makeAreas = (...rows: [item: GridTemplate, render: boolean][][]) => {
  const rowAreas = rows.map((items) => {
    const renderedItems = items.filter(([, render]) => !!render);
    const renderedAreas = renderedItems.map(([item]) => item.area);
    return renderedAreas.join(" ");
  });

  const renderedRowAreas = rowAreas.filter((row) => row.length);

  return css`
    grid-template-areas: ${renderedRowAreas
      .map((areas) => `"${areas}"`)
      .join(" ")};
  `;
};

const makeColumns = (...items: [item: GridTemplate, render: boolean][]) => {
  const renderedItems = items
    .filter(([, render]) => !!render)
    .map(([item]) => item);
  const columns = renderedItems.map((item) => item.column);
  return css`
    grid-template-columns: ${columns.join(" ")};
  `;
};

const StyledNavHeader = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
  color: ${(p) => p.theme.colors.neutrals.textInverse};
`;

const StyledNavHeaderInner = styled.div<StyledNavigationHeaderProps>`
  display: grid;
  align-items: center;

  ${(p) =>
    makeAreas(
      [
        [gridMap.nav, true],
        [gridMap.title, true],
        [gridMap.actions, p.hasActions],
      ],
      [
        [gridMap.tabs, p.hasTabs],
        [gridMap.tabs, p.hasTabs],
        [gridMap.tabs, p.hasTabs && p.hasActions],
      ],
    )}
  ${(p) =>
    makeColumns(
      [gridMap.nav, true],
      [gridMap.title, true],
      [gridMap.actions, p.hasActions],
    )}
  grid-template-rows: minmax(2.5rem, auto) auto;
  column-gap: 0.5rem;
  row-gap: 0.375rem;
  width: 100%;
  padding: 0.375rem 0.625rem 0.5rem;

  @media ${media.lg} {
    padding: 0.25rem 0.75rem;
    grid-template-rows: auto;
    column-gap: 0.75rem;
    row-gap: 1rem;
    min-height: 3.5rem;

    ${(p) =>
      makeAreas([
        [lgGridMap.nav, true],
        [lgGridMap.title, true],
        [lgGridMap.tabs, p.hasTabs],
        [lgGridMap.actions, p.hasActions],
      ])};
    ${(p) =>
      makeColumns(
        [lgGridMap.nav, true],
        [lgGridMap.title, true],
        [lgGridMap.tabs, p.hasTabs],
        [lgGridMap.actions, p.hasActions],
      )};
  }
`;

const StyledNav = styled.div`
  grid-area: nav;
`;

const StyledTitle = styled.div`
  ${(p) => p.theme.typography.variants.subtitleLg}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${media.lg} {
    ${(p) => p.theme.typography.variants.subtitleLg}
  }
`;

const StyledTabs = styled.div`
  grid-area: tabs;
  width: 100%;
  padding: 0 0 0.25rem;

  @media ${media.lg} {
    width: auto;
    padding: 0 0 0 0.5rem;
  }
`;

const StyledActions = styled.div`
  grid-area: actions;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export interface NavHeaderProps {
  actions?: React.ReactNode;
  "data-testid"?: string;
  nav: React.ReactNode;
  tabList?: React.ReactNode;
  title: string | React.ReactNode;
}

const NavHeader: ForwardRefRenderFunction<HTMLDivElement, NavHeaderProps> = (
  { actions, "data-testid": dataTestId, nav, tabList, title }: NavHeaderProps,
  ref,
) => {
  const hasActions = !!actions;
  const hasTabs = !!tabList;

  return (
    <InverseSkeletonTheme>
      <StyledNavHeader ref={ref}>
        <StyledNavHeaderInner
          data-testid={dataTestId}
          hasActions={hasActions}
          hasTabs={hasTabs}
        >
          <StyledNav>{nav}</StyledNav>
          <StyledTitle data-testid={dataTestId && `${dataTestId}-title`}>
            {title}
          </StyledTitle>
          {hasTabs && (
            <StyledTabs>
              <HeaderLinkTabs>{tabList}</HeaderLinkTabs>
            </StyledTabs>
          )}
          {hasActions && <StyledActions>{actions}</StyledActions>}
        </StyledNavHeaderInner>
      </StyledNavHeader>
    </InverseSkeletonTheme>
  );
};

export default forwardRef(NavHeader);
