import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import type { FieldError as HookFormFieldError } from "react-hook-form";
import styled from "styled-components";
import Checkbox from "../Checkbox/Checkbox";
import StatusMessage from "../StatusMessage/StatusMessage";
import CheckboxGroupProvider, {
  useCheckboxGroup,
} from "./CheckboxGroupProvider";

const StyledCheckboxGroup = styled.fieldset`
  display: grid;
  gap: 0.5rem;
`;

const StyledLegend = styled.legend`
  ${(p) => p.theme.typography.variants.label}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  margin-bottom: 0.5rem;
`;

const StyledCheckboxes = styled.div`
  display: grid;
  gap: 0.25rem;
`;

export interface CheckboxGroupProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  error?: HookFormFieldError | string;
  label: React.ReactNode;
  onBlur: React.FocusEventHandler<HTMLFieldSetElement>;
  onChange: (value: string[]) => void;
  value: string[];
}

const CheckboxGroup: ForwardRefRenderFunction<
  HTMLFieldSetElement,
  CheckboxGroupProps
> = (
  {
    children,
    "data-testid": dataTestId,
    error,
    label,
    onBlur,
    onChange: _onChange,
    value,
  }: CheckboxGroupProps,
  ref,
) => {
  let errorMessage: string | undefined;
  if (typeof error === "string") {
    errorMessage = error;
  } else if (error) {
    errorMessage = error.message;
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      _onChange([...value, event.currentTarget.value]);
    } else {
      _onChange(value.filter((val) => val !== event.currentTarget.value));
    }
  };

  return (
    <CheckboxGroupProvider onChange={onChange} checkedItems={value}>
      <StyledCheckboxGroup data-testid={dataTestId} onBlur={onBlur} ref={ref}>
        <StyledLegend>{label}</StyledLegend>
        {children && <StyledCheckboxes>{children}</StyledCheckboxes>}
        {errorMessage && (
          <StatusMessage variant="error">{errorMessage}</StatusMessage>
        )}
      </StyledCheckboxGroup>
    </CheckboxGroupProvider>
  );
};

interface CheckboxGroupItemProps {
  disabled?: boolean;
  hint?: React.ReactNode;
  label: React.ReactNode;
  value: string;
}

const CheckboxGroupItem = ({
  disabled,
  hint,
  label,
  value,
}: CheckboxGroupItemProps) => {
  const { onChange, checkedItems } = useCheckboxGroup();

  return (
    <Checkbox
      checked={checkedItems.includes(value)}
      data-testid={`checkbox-${value}`}
      disabled={disabled}
      hint={hint}
      label={label}
      onChange={onChange}
      value={value}
    />
  );
};

export default Object.assign(forwardRef(CheckboxGroup), {
  Item: CheckboxGroupItem,
});
